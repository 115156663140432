var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "section-content" },
      [
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _c(
              "div",
              { staticClass: "vld-parent" },
              [
                _c("loading", {
                  attrs: {
                    active: _vm.isLoading,
                    "can-cancel": false,
                    "is-full-page": _vm.fullPage,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.isLoading = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "header-container shadow-sm p-3 mb-5 bg-white rounded",
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "new-user-button",
                    on: {
                      click: function ($event) {
                        return _vm.newTranscription()
                      },
                    },
                  },
                  [_vm._v(" Nova transcrição ")]
                ),
                _c(
                  "b-form-radio-group",
                  {
                    staticClass: "search-filter",
                    attrs: { "button-variant": "outline-primary", buttons: "" },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  },
                  [
                    _c(
                      "b-form-radio",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: { value: "name", title: "Nome da transcrição" },
                      },
                      [_vm._v("Nome")]
                    ),
                    _c(
                      "b-form-radio",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: { value: "content", title: "Conteúdo" },
                      },
                      [_vm._v("Conteúdo")]
                    ),
                    _c(
                      "b-form-radio",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          value: "date",
                          title: "Formato aceito: 15(dia), 2020, 15/01/2020",
                        },
                      },
                      [_vm._v("Data")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "search-container" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mb-0", attrs: { size: "sm" } },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                        _c("b-form-input", {
                          attrs: { type: "search", placeholder: "Buscar..." },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onSearchClick()
                            },
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-table", {
              staticClass: "shadow-sm bg-white rounded",
              attrs: {
                striped: "",
                fields: _vm.fields,
                items: _vm.transcripts,
                "per-page": 0,
                "current-page": _vm.currentPage,
                stacked: "md",
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(name)",
                  fn: function (row) {
                    return [
                      _c("div", [_vm._v(_vm._s(row.value))]),
                      row.item.content
                        ? _c(
                            "div",
                            { staticStyle: { "font-family": "Arial" } },
                            [
                              _c("i", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatSearchedText(row.item.content)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(status)",
                  fn: function (row) {
                    return [
                      _c("img", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true },
                          },
                        ],
                        attrs: {
                          src: _vm.getStatusIconStyle(row.item),
                          title: _vm.getStatusIconTooltip(row.item),
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(edit)",
                  fn: function (row) {
                    return [
                      _c("img", {
                        staticClass: "item-table",
                        attrs: { src: _vm.editIcon },
                        on: {
                          click: function ($event) {
                            return _vm.openTranscriptionDetail(
                              row.item,
                              row.index,
                              $event.target
                            )
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(remove)",
                  fn: function (row) {
                    return [
                      _c("img", {
                        staticClass: "item-table",
                        attrs: { src: _vm.deleteIcon },
                        on: {
                          click: function ($event) {
                            return _vm.deleteTranscription(
                              row.item,
                              row.index,
                              $event.target
                            )
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              {
                staticClass: "pagination-container shadow-sm bg-white rounded",
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "my-3" },
                      [
                        _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updatePage()
                            },
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "my-3" },
                      [
                        _c("b-form-select", {
                          staticStyle: { "min-width": "100px" },
                          attrs: {
                            id: "per-page-select",
                            options: _vm.pageOptions,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updatePage()
                            },
                          },
                          model: {
                            value: _vm.perPage,
                            callback: function ($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "modal",
            attrs: {
              title: "",
              "cancel-variant": "transparent",
              size: "lg",
              "hide-footer": "",
            },
            on: { hidden: _vm.resetModal },
            scopedSlots: _vm._u([
              {
                key: "modal-title",
                fn: function () {
                  return [
                    _c("h4", { staticClass: "mt-2 pb-0" }, [
                      _vm._v("Nova transcrição"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "div",
              [
                _vm.showTranscriptionTab
                  ? _c(
                      "b-form",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "tab-bold",
                            attrs: {
                              label: "Nome da transcrição",
                              "label-for": "name-input",
                              state: _vm.transcriptionNameState,
                              "invalid-feedback":
                                _vm.transcriptionNameInvalidFeedback,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                state: _vm.transcriptionNameState,
                                id: "name-input",
                              },
                              model: {
                                value: _vm.transcription.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.transcription, "name", $$v)
                                },
                                expression: "transcription.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "tab-bold",
                            attrs: {
                              label: "Descrição da transcrição",
                              "label-for": "monitor-description",
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "monitor-description" },
                              model: {
                                value: _vm.transcription.description,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.transcription,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "transcription.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              state: Boolean(_vm.file),
                              "invalid-feedback":
                                "É necessário selecionar um arquivo",
                            },
                          },
                          [
                            _c("b-form-file", {
                              attrs: {
                                state: Boolean(_vm.file),
                                placeholder: _vm.fileName,
                                accept:
                                  "audio/mpeg, audio/mp3, audio/aac, video/mp4",
                                "browse-text": "Escolha o arquivo",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeFile()
                                },
                              },
                              model: {
                                value: _vm.file,
                                callback: function ($$v) {
                                  _vm.file = $$v
                                },
                                expression: "file",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showFileProperties,
                                expression: "showFileProperties",
                              },
                            ],
                          },
                          [
                            _c(
                              "b-form",
                              { attrs: { inline: "" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "multichannelAudio mt-3",
                                    attrs: {
                                      value: "accepted",
                                      "unchecked-value": "not_accepted",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeMultiChannelCheckBox(
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.multiChannelChecked,
                                      callback: function ($$v) {
                                        _vm.multiChannelChecked = $$v
                                      },
                                      expression: "multiChannelChecked",
                                    },
                                  },
                                  [_vm._v(" Áudio multicanal ")]
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true },
                                    },
                                  ],
                                  ref: "showAfterFile",
                                  staticStyle: {
                                    "margin-top": "5px",
                                    "margin-left": "8px",
                                  },
                                  attrs: {
                                    src: require("@/assets/icons/icone_i.svg"),
                                    title: _vm.audioInformation,
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showDiarization,
                                        expression: "showDiarization",
                                      },
                                    ],
                                    staticClass: "tab mt-4",
                                    staticStyle: { "margin-left": "370px" },
                                    attrs: { disabled: "" },
                                  },
                                  [_vm._v("Locutores:")]
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showDiarization,
                                      expression: "showDiarization",
                                    },
                                  ],
                                  staticClass: "mt-2",
                                  staticStyle: {
                                    "font-size": "17px",
                                    "line-height": "20px",
                                    height: "30px",
                                    "margin-left": "18px",
                                    "margin-top": "-18px",
                                    width: "84px !important",
                                    "border-radius": "2px",
                                  },
                                  attrs: {
                                    type: "number",
                                    min: "1",
                                    max: "10",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.showTranscriptionTab
                  ? _c(
                      "b-form",
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "tab mt-4" },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "aboutAccuracy",
                                staticStyle: {
                                  "font-weight": "600",
                                  "margin-bottom": "30px !important",
                                },
                              },
                              [_vm._v("Canais de áudio (multicanal)")]
                            ),
                            _vm._l(
                              _vm.audioChannelCount,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "b-form",
                                      { attrs: { inline: "" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            style: _vm.channelStyle(
                                              item.channel
                                            ),
                                            attrs: { disabled: "" },
                                          },
                                          [_vm._v(_vm._s(item.channel))]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "channels",
                                          attrs: {
                                            type: "text",
                                            placeholder: item.announcer,
                                          },
                                          model: {
                                            value:
                                              _vm.audioChannelCount[index]
                                                .announcer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.audioChannelCount[index],
                                                "announcer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "audioChannelCount[index].announcer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("br"),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("b-form", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.file != null,
                          expression: "file != null",
                        },
                      ],
                    },
                    [
                      _c("b-progress", {
                        staticClass: "mb-2",
                        staticStyle: {
                          "background-color": "#DEF1FF",
                          "margin-top": "15px",
                        },
                        attrs: {
                          height: "13px",
                          value:
                            this.$store.state.transcription.percentCompleted,
                          "show-progress": "",
                        },
                      }),
                      _c("img", { attrs: { src: _vm.imgStatusAccuracy } }),
                      _c(
                        "p",
                        {
                          staticClass: "tab",
                          staticStyle: {
                            "margin-top": "15px",
                            "margin-left": "10px",
                            display: "inline-block",
                            "line-height": "20px",
                          },
                        },
                        [
                          _vm._v("Potencial de "),
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(_vm.accuracyStatus))]
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "tab",
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "14px",
                            "margin-left": "275px",
                          },
                        },
                        [
                          _vm._v("Duração do áudio: "),
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(" " + _vm._s(_vm.formatedFileDuration))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "knowMore",
                              on: {
                                click: function ($event) {
                                  return _vm.onKnowMoreClick()
                                },
                              },
                            },
                            [_vm._v("Saber mais")]
                          ),
                          _c("img", {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { src: require("@/assets/icons/arrow.svg") },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-sm-12 bottom-bar" }, [
                    _c("ul", { staticClass: "list-inline float-right" }, [
                      _c(
                        "li",
                        { staticClass: "list-inline-item" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-secondary btn-transparent",
                              on: {
                                click: function ($event) {
                                  return _vm.onButtonBackActionClick()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnBackAction))]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-primary",
                              staticStyle: { width: "134px" },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.onButtonNextActionClick()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnNextAction))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        ),
        _c(
          "b-modal",
          {
            ref: "accuracyModal",
            staticClass: "body",
            attrs: {
              "ok-title": _vm.submitTitle,
              "cancel-title": "Cancelar",
              "cancel-variant": "transparent",
              "hide-footer": "",
            },
            on: { ok: _vm.handleModalOk },
          },
          [
            _c("h4", { staticClass: "ml-0" }, [
              _vm._v("Conte-nos sobre o que você deseja transcrever:"),
            ]),
            _c(
              "b-form-group",
              { staticClass: "tab" },
              [
                _vm._l(_vm.options, function (option) {
                  return _c(
                    "b-form-checkbox",
                    {
                      key: option.value,
                      attrs: { value: option.value },
                      on: {
                        change: function ($event) {
                          return _vm.onAccuracySelectedChange(_vm.event)
                        },
                      },
                      model: {
                        value: _vm.selecteds,
                        callback: function ($$v) {
                          _vm.selecteds = $$v
                        },
                        expression: "selecteds",
                      },
                    },
                    [_vm._v(" " + _vm._s(option.text) + " ")]
                  )
                }),
                _c("img", { attrs: { src: _vm.imgStatusAccuracy } }),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-top": "15px",
                      "margin-left": "10px",
                      display: "inline-block",
                      "line-height": "20px",
                    },
                  },
                  [
                    _vm._v("Potencial de "),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(_vm._s(_vm.accuracyStatus)),
                    ]),
                  ]
                ),
                _c("p", { staticClass: "aboutAccuracy mt-4" }, [
                  _vm._v("Saiba mais sobre o assunto em "),
                  _c("a", { attrs: { href: _vm.site, target: "blanc" } }, [
                    _vm._v("www.retextoar.com.br"),
                  ]),
                ]),
              ],
              2
            ),
            _c(
              "footer",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-primary btn-close",
                    on: {
                      click: function ($event) {
                        return _vm.hideAcurracyModal()
                      },
                    },
                  },
                  [_vm._v("Voltar a transcrição")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Transcrições")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }